<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/squint_metrics_plugin.svg"
          alt="squint metrics logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} Squint Metrics Plugin
      </template>
      <template #subheader>
        The Squint Metrics Plugin allows Pulse to send opt in and opt out data to Squint Metrics.
      </template>
    </PageHeader>

    <div class="mt-10">
      <validation-observer
        ref="inputRef"
      >
        <div>
          <CustomTextInput
            v-model="username"
            header="Username"
            required
          />
        </div>
        <div>
          <CustomTextInput
            v-model="password"
            type="password"
            header="Password"
            required
          />
        </div>
        <div>
          <CustomTextInput
            v-model="apiUrl"
            header="Squint Metrics API URL"
            required
          />
        </div>
        <div>
          <CustomDropdown
            v-model="brand"
            placeholder="Select an Option"
            class="mt--2"
            :items="brands"
            item-value="id"
            item-text="name"
            header="Brand"
            required
          />
        </div>
        <div>
          <CustomTextInput
            v-model="alertNotificationRecipients"
            header="Alert Notification Recipients"
            hint="A comma separated list of email addresses that should receive an alert notification if contact updates fail when sending to Squint Metrics API."
          />
        </div>
      </validation-observer>
      <v-row class="mt-12">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            v-if="installed"
            class="custom-button custom-button--red-text px-8 mr-4"
            height="34px"
            depressed
            @click="deactivateDialog = true;"
          >
            Deactivate
          </v-btn>

          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="trigger"
          >
            {{ plugin ? 'Update' : 'Install' }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddPluginSquintMetrics",
  metaInfo: {
    title: 'Install Squint Metrics'
  },
  components: {
    CustomTextInput,
    CustomDropdown,
    DeactivateConfirmDialog,
    PageHeader,
  },
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    username: null,
    password: null,
    brand: null,
    alertNotificationRecipients: null,
    apiUrl: 'https://accoustic-pulse.squintmetrics.com/',
    brands: [
      {id: null, name: 'Select an Option'},
      {id: 'exparel', name: 'Exparel'},
      {id: 'iovera', name: 'Iovera'},
      {id: 'zilretta', name: 'Zilretta'},
    ],
    plugin: null,
  }),
  async created() {
    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadAccountPlugin() {
      const response = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!response.data) {
        return;
      }

      this.plugin = {...response.data};
      this.username = this.plugin?.pluginConfiguration?.credentials?.username;
      this.password = this.plugin?.pluginConfiguration?.credentials?.password;
      this.apiUrl = this.plugin?.pluginConfiguration?.apiUrl ? this.plugin.pluginConfiguration.apiUrl : 'https://accoustic-pulse.squintmetrics.com/';
      this.brand = this.plugin?.pluginConfiguration?.brand;
      this.alertNotificationRecipients = this.plugin?.pluginConfiguration?.alertNotificationRecipients;
    },
    trigger() {
      if (this.plugin) {
        return this.updatePlugin();
      } else {
        return this.installPlugin();
      }
    },
    async updatePlugin() {
      const pluginConfiguration = {
        credentials: {
          username: this.username,
          password: this.password,
        },
        apiUrl: this.apiUrl,
        brand: this.brand,
        alertNotificationRecipients: this.alertNotificationRecipients,
      };

      const response = await this.$rest.plugins.put_resource(this.id, {
        ...this.plugin,
        plugin: this.plugin.plugin.id,
        pluginConfiguration
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to update plugin',
          color: 'error',
        });
      }

      this.$store.commit('snackbar/showMessage', {
        content: 'Plugin updated successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
    async installPlugin() {
      const pluginConfiguration = {
        credentials: {
          username: this.username,
          password: this.password,
        },
        brand: this.brand,
        apiUrl: this.apiUrl,
        alertNotificationRecipients: this.alertNotificationRecipients,
      };
      const response = await this.$rest.plugins.post_resource({
        plugin: +this.id,
        isActive: true,
        pluginConfiguration,
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to install plugin',
          color: 'error',
        });
      }

      const selected_account = this.$store.getters["user/account"];
      this.$store.commit("user/update_selected_account", {
        ...selected_account,
        activePlugins: [...selected_account.activePlugins, response.data],
      });

      this.$store.commit('snackbar/showMessage', {
        content: 'Installed plugin successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
  },
}
</script>

<style scoped>

</style>
